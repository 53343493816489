.project{
  width:100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.linksToProject {
  width: 90%;
  font-size: 1.2rem;
  text-align: center;
}

.linksToProject > h3 {
  margin: 0;
}

.iconGroup {
  font-size: 1.3rem;
  list-style: none;
  padding: 0;
}

.iconGroup li {
  display:inline-block;
}


i {
  margin: 3px;
}

ul {
  list-style:none;
  padding: 0;
  margin: 0;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects > h2 {
margin-bottom: 2px;
margin-top: 0px;
position: relative;
top: -13px;

}

.projects > h3 {
  margin: 2px;
  text-align:center;
  
}

.multipleRepos{
  font-size: 1rem;
}

.project-image {
  margin-top: 1%;
  margin-bottom:1%;
  width:100%;
  }

  .multipleRepos > a {
    margin: 5px;
  }

  .dividerLine {
    width: 80%;
    border-top: 5px solid #222532;
    margin: 20px auto;
  }

  @media only screen and (min-width: 350px) {
    .project-image {
      width: 100%;
    }
    .multipleRepos{
      font-size: 1.2rem;
    }
  }
  
  @media only screen and (min-width: 400px) {
    .project-image {
      width: 90%;
    }
  }
  
  @media only screen and (min-width: 500px) {
    .project-image {
      width: 70%;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .project-image {
      width: 60%;
    }
  }
  
  @media only screen and (min-width: 700px) {
  .project-image {
    width: 55%;
  }
}
  
@media only screen and (min-width: 900px) {
  .projects > p {
    width: 70%;
    margin: 10px auto;
  }
}

@media only screen and (min-width: 1200px) {
    .project-image {
      width: 50%;
    }
  }
* {
  box-sizing: border-box;
  overscroll-behavior: none;
}


html {
  font-size: 16px;
}

/* this styling is used so that the fixed background-image works on 
mobile devices as well */
html:before {
    background-color:#22242D;
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    background: url('./images/trees.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media (hover: none) {
  .homeHero {
      background-attachment: scroll;

  }
}

body {
  font-family: 'Fira Sans Condensed', sans-serif;
  line-height: 1.5;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


h1, h2, h3, h4 {
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 1.3rem;
}

.sectionHeading {
  font-family: 'Rochester', cursive;
  font-weight: bolder;
  font-size: 2rem;
}

section {
    width: 90%;
    padding: 30px;
    background-color: rgb(39, 56, 56, 0.7);
    color:rgb(199, 202, 213);
    margin: 10% auto;
}

  #welcome, #nav, .notFound {
    -webkit-animation: fadeinout 3s linear forwards;
    animation: fadeinout 3s linear forwards;
  }
  
  @-webkit-keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes fadeinout {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media only screen and (min-width: 500px) {
  section{
    width: 85%
  }
}

@media only screen and (min-width: 900px) {
  section{
    width: 65%
  }

  html {
    font-size: 18px;
  }
}
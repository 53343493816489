.contact-info {
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40vh;
}

.email > a {
  font-size: 0.75rem;
}


.contactIconLinks {
  font-size: 2rem;
}

@media only screen and (min-width: 380px) {
  .email > a {
    font-size: 1rem;
  }
}
nav {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: 'Fira Sans Condensed', sans-serif;
  width:100%;
  padding: 2% 0;
  background-color: #4B595B;
  color:rgb(199, 202, 213);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size:1.2rem;
  z-index:200;
}

.navBarList{
  display:flex;
  justify-content: center;
}

.needDivider::after {
  content: '|';
  color: grey;
  margin-left: 2px;
  margin-right:2px;
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: rgb(199, 202, 213);
}

a:hover {
  color:#232A35;
  font-weight: bolder;
  cursor:pointer;
}

a:active {
  color: white;
}

ul {
  list-style:none;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 700px) {
  .needDivider::after{
    margin-left: 5px;
    margin-right: 5px;
  }

  nav {
    padding: 1% 0;
  }

}
.initials {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: rgb(39, 56, 56, 0.7);
  color:rgb(199, 202, 213);
  display:flex;
  justify-content:center;
  align-items:center;
}

.initials > h1 {
  font-size: 7rem;
  font-family: 'Rochester', cursive;
  font-weight: normal;
  
}

header {
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-around;
  text-align: center;
  color:rgb(199, 202, 213);
  height: 100vh;
  width: 100%;
}


  .introStatements > h1, .introStatements > h2 {
    font-size: 1.3rem;
  }

  .introStatements > h2{
    font-size: 1.1rem;
  }

  .initials {
    background: rgb(39, 56, 56, 0.7);
    -webkit-transition-property: background;
    -moz-transition-property: background;
    -o-transition-property: background;
    transition-property: background;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -o-transition-timing-function: linear;
    transition-timing-function: linear;
}
.initials:hover {
  background: rgba(0, 2, 18, 0.7);
  cursor:pointer;
}
  
  @media only screen and (min-width: 700px) {
    .initials {
      height: 220px;
      width: 220px;
    }
  }

  @media only screen and (min-height: 1300px) {
    .initials {
      height: 420px;
      width: 420px;
    }

    .initials > h1 {
      font-size: 10rem;
    }

    .introStatements > h1 {
      font-size: 2rem;
    }

    .introStatements > h2{
      font-size: 1.6rem;
    }
  }
